<template>
  <div class="wrap"
       style="padding-bottom: 0rem;">
    <div class="label">
      {{index}}.
    </div>

    <!-- 非7选5选择题 -->
    <div class="items"
         v-if="type ==1">
      <div class="item-container"
           v-for="item in selectOptions1"
           :key="item">
        <div :class="{'item':true,'pitchOn':answer.indexOf(item) != -1}"
             @click="add(item)">
          {{item}}
        </div>

      </div>
    </div>
    <!-- 3选一 -->
    <div class="items"
         v-if="type == 12">

      <div class="item-container"
           v-for="item in selectOptions3"
           :key="item">
        <div :class="{'item':true,'pitchOn':answer.indexOf(item) != -1}"
             @click="add(item)">
          {{item}}
        </div>
      </div>
    </div>
    <!-- 6选x -->
    <div class="items"
         v-if="type == 13">

      <div class="item-container"
           v-for="item in selectOptions5"
           :key="item">
        <div :class="{'item':true,'pitchOn':answer.indexOf(item) != -1}"
             @click="add(item)">
          {{item}}
        </div>
      </div>
    </div>
    <!-- 5选x -->
    <div class="items"
         v-if="type == 14">

      <div class="item-container"
           v-for="item in selectOptions4"
           :key="item">
        <div :class="{'item':true,'pitchOn':answer.indexOf(item) != -1}"
             @click="add(item)">
          {{item}}
        </div>
      </div>
    </div>
    <!-- 7选5选择题 -->
    <div class="items"
         v-if="type ==9">
      <div class="item-container"
           v-for="item in selectOptions2"
           :key="item">
        <div :class="{'item':true,'pitchOn':answer.indexOf(item) != -1}"
             @click="add(item)"
             :key="item">
          {{item}}
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      selectOptions1: ['A', 'B', 'C', 'D'],
      selectOptions2: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
      selectOptions3: ['A', 'B', 'C'],
      selectOptions4: ['A', 'B', 'C', 'D', 'E'],
      selectOptions5: ['A', 'B', 'C', 'D', 'E', 'F'],
    }
  },
  mounted () {
    console.log('999', this.answer)
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    answer: {
      type: Array,
      default: () => {
        return []
      }
    },
    questionId: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    }
  },
  methods: {
    add (item) {
      this.$emit('setAnwer', this.questionId, item)
    },
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  align-items: center;
  /*padding-top: 30rem;*/
  overflow: hidden;
  .label {
    width: 50rem;
    font-size: 40rem;
    /*height: 133rem;*/
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0rem 25rem 0 20rem;
  }
  .items {
    width: 100%;
    display: flex;
    flex-direction: row;
    .item-container {
      width: 14.285%;
      .item {
        aspect-ratio: 1;
        margin: 15rem;
        background: #ffffff;
        border-radius: 15rem 15rem 15rem 15rem;
        opacity: 1;
        border: 2rem solid #cccccc;
        font-size: 30rem;
        display: flex;
        justify-content: center;
        align-items: center;

        color: #cccccc;
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  }
}
.pitchOn {
  color: #2196f3 !important;
  border: 3rem solid #2196f3 !important;
}
</style>